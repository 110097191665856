import React, {useState} from 'react';
import AnimateCC from "react-adobe-animate";

const Helikopter_Animation = () => {
    const [, setAnimationObject] = useState(null);
    const getAnimationObject = obj => setAnimationObject(obj);

    return (
        <div className="animation animation-helicopter">
            <AnimateCC
                animationName="Helikopter_Animation"
                getAnimationObject={getAnimationObject}
                composition="81115028747444FAA6227DEA6145F27B"
            />
        </div>
    );
};

export default Helikopter_Animation;

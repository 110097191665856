import React from 'react';
import {Container} from 'react-bootstrap'
import {Link, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {authUser} from "../../actions/auth";
import {loadUser} from "../../actions/user";
import PropTypes from 'prop-types';
import TravelLogo from "../layout/TravelLogo";
import {setAuthToken} from "../../utils/setAuthToken";
import {SlideDown} from 'react-slidedown'
import {motion} from "framer-motion";


if (localStorage.token) {
    setAuthToken(localStorage.token);
}

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            errorText: false,
            show: true,
            inputValuesDisplayed: [
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ],
            accessCode: ''
        };
    }

    initialInputValuesDisplayed = [
        '',
        '',
        '',
        '',
        '',
        '',
        ''
    ];

    displayFormChar = () => {
        this.setState({show: false});
        setTimeout(() => {
            this.setState({
                show: true
            });
        }, 2000);
    };

    submitHandler = async () => {
        if (this.state.accessCode.length !== this.state.inputValuesDisplayed.length) {
            this.setState({
                error: true,
                errorText: true,
                inputValuesDisplayed: [
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    ''
                ],
                accessCode: ''
            });
            setTimeout(() => {
                this.setState({
                    error: false
                });
            }, 1000);
        } else {
            await this.props.authUser(this.state.accessCode);
            await this.props.loadUser(this.state.accessCode);
            if (this.props.isAuthenticated) {
                return <Redirect to="/welcome/"/>
            } else {
                this.setState({
                    error: true,
                    errorText: true,
                    inputValuesDisplayed: [
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        ''
                    ],
                    accessCode: ''
                });
                setTimeout(() => {
                    this.setState({
                        error: false,
                        inputValuesDisplayed: [
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            ''
                        ],
                        accessCode: ''
                    });
                }, 1000);
            }
        }
    };

    changeInputValue = (event) => {
        const targetValue = event.target.value;
        let inputValuesDisplayed = this.state.inputValuesDisplayed;

        // eslint-disable-next-line
        targetValue.split('').map((x, index) => {
            if (index === targetValue.length - 1) {
                if (index === inputValuesDisplayed.length - 1) {
                    this.displayFormChar();
                    inputValuesDisplayed[index] = x + 'L';
                } else {
                    inputValuesDisplayed[index] = x;
                }
            } else {
                inputValuesDisplayed[index] = '*'
            }
        });

        for (let i = targetValue.length; i < inputValuesDisplayed.length; i++) {
            inputValuesDisplayed[i] = '';
        }

        this.setState({
            inputValuesDisplayed: inputValuesDisplayed,
            accessCode: targetValue
        })
    };

    render() {

        let inputValuesAsterisk = this.state.inputValuesDisplayed.map((x, index) => {
            if (x === '') {
                return <span key={index} className="input-group__fields-active-underlined"></span>
            } else if (x === '*') {
                return <span key={index} className="input-group__fields-active-asterisk icon-input-star"></span>
            } else if (x[1] === 'L') {
                return (this.state.show ?
                        <span key={index}
                              className="input-group__fields-active-asterisk icon-input-star"></span> :
                        <span key={index}
                              className="input-group__fields-active-char">{x[0]}</span>
                )
            } else {
                return <span key={index} className="input-group__fields-active-char">{x[0]}</span>
            }
        });


        if (this.props.isAuthenticated) {
            return <Redirect to="/welcome/"/>
        }

        return (
            <motion.div className="page-wrapper align-center login"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: 1.5}}
            >
                <TravelLogo/>
                <Container fluid className="d-flex justify-content-center">
                    <div className="input-group__wrapper">
                        <p className="text">
                            Bitte geben Sie Ihren persönlichen Code ein.<br/>
                            Er befindet sich auf der Vorderseite Ihres Tickets.
                        </p>
                        <div className="input-group__fields">
                            <input className="input-group__fields-input" maxLength={7} type="text"
                                   value={this.state.accessCode}
                                   onChange={this.changeInputValue}/>
                            <div
                                className={this.state.error ? 'input-group__fields-active error' : 'input-group__fields-active'}>
                                {inputValuesAsterisk}
                            </div>
                            <SlideDown closed={!this.state.errorText}>
                                <p className="text--small">
                                    Dein Code war leider falsch.<br/>
                                    Versuch es bitte nochmals.
                                </p>
                            </SlideDown>
                        </div>
                        <div className="submitarea">
                            <button className="btn btn-primary" onClick={this.submitHandler}>Check-In</button>
                        </div>
                    </div>
                </Container>
                <Container fluid className="d-flex justify-content-center">
                    <p className="text--small mw-350">
                        Sie haben keinen persönlichen Code und möchten trotzdem mit DACHCOM TRAVEL verreisen?<br/>
                        <Link to='/welcome'> Klicken Sie hier</Link>
                    </p>
                </Container>
            </motion.div>
        );
    };
}

Login.propTypes = {
    loadUser: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {authUser, loadUser})(Login);
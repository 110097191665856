import axios from 'axios';
import {USER_LOADED, USER_ERROR} from "./types";
import {setAuthToken} from './../utils/setAuthToken';

//Load User
export const loadUser = (accessCode) => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const res = await axios.get('/api/users/' + accessCode);
        dispatch({
            type: USER_LOADED,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: USER_ERROR
        })
    }
};
import React, {useState} from 'react';
import {motion} from "framer-motion";
import {Container, Row, Col} from "react-bootstrap";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {addCountToDestination} from "../../../actions/destination";
import AnimateCC from "react-adobe-animate";
import TravelLogo from "../../layout/TravelLogo";
import {Link} from "react-router-dom";
import Tilt from "react-tilt";

const DetailDestinationAnimation = (props) => {
    const [, setAnimationObject] = useState(null);
    const getAnimationObject = obj => setAnimationObject(obj);

    return (
        <div className="animation animation-destination">
            <AnimateCC
                animationName={props.animationName}
                getAnimationObject={getAnimationObject}
                composition={props.animationComposition}
            />
        </div>
    );
};

class DetailDestination extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rendered: false
        }
    }

    componentDidMount() {
        this.setState({
            rendered: true
        });
        if (!this.state.rendered) {
            this.props.addCountToDestination(this.props.country.route);
        }
    }

    render() {
        return (
            <motion.div className={'page-wrapper align-center destination-detail ' + this.props.country.route}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: 1.5}}>
                <div className="d-none d-md-block">
                    <TravelLogo/>
                </div>
                <Container fluid>
                    <Row>
                        <Col xl={6} className="destination-detail__fixed">
                            <div className="destination-detail__fixed-landmap mb-4 d-none d-xl-flex">
                                <Tilt options={{max: 35}}>
                                    <img src={this.props.country.landmap} alt="Landkarte"/>
                                </Tilt>
                            </div>
                            <div className="destination-detail__fixed-buttons">
                                <Link to="/destinations" className="btn">Weiterreisen</Link>
                                <Link to="/message" className="btn btn-primary">Check Out</Link>
                            </div>
                        </Col>
                        <Col xl={6} className="destination-detail__scrollable">
                            <Row>
                                <Col xs={12} className="destination-detail__title d-none d-md-flex">
                                    <h2>{this.props.country.name}</h2>
                                </Col>
                                <Col xl={6}>
                                    <Row className="mb-4">
                                        <Col xs={12} md={6} xl={12} className="destination-detail__head">
                                            <div className="destination-detail__head-animation">
                                                <DetailDestinationAnimation
                                                    animationName={this.props.country.animationName}
                                                    animationComposition={this.props.country.animationComposition}
                                                />
                                            </div>
                                            <div className="destination-detail__head-title d-md-none">
                                                <h1>{this.props.country.name}</h1>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={6}
                                             className="d-none d-md-flex d-xl-none destination-detail__map">
                                            <div className="destination-detail__map-landmap">
                                                <Tilt options={{max: 35}}>
                                                    <img src={this.props.country.landmap} alt="Landkarte"/>
                                                </Tilt>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={6}>
                                    <Row>
                                        <Col xs={12} className="destination-detail__facts">
                                            <Row>
                                                <Col md={6} xl={12}>
                                                    <Row>
                                                        <Col xs={12}
                                                             className="destination-detail__facts-capital">
                                                            <p className="destination-detail__facts-head">
                                                                Hauptstadt
                                                            </p>
                                                            <p className="destination-detail__facts-content">
                                                                {this.props.country.capital}
                                                            </p>
                                                        </Col>
                                                        <Col xs={12} className="destination-detail__facts-reason">
                                                            <p className="destination-detail__facts-head">
                                                                Warum sie hinsollten
                                                            </p>
                                                            <p className="destination-detail__facts-content">
                                                                {this.props.country.reasonToVisit}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={6} xl={12}>
                                                    <Row className="d-md-flex align-content-between flex-wrap">
                                                        <Col xs={12} className="destination-detail__facts-tradition">
                                                            <p className="destination-detail__facts-head">
                                                                Weihnachtsbrauch
                                                            </p>
                                                            <p className="destination-detail__facts-content">
                                                                {this.props.country.tradition}
                                                            </p>
                                                        </Col>
                                                        <Col xs={12} className="destination-detail__facts-visited">
                                                            <div className="destination-detail__facts-visited-count">
                                                                <div
                                                                    className="destination-detail__facts-visited-count-wrapper">
                                                                    <p>{this.props.visitedCount}</p>
                                                                </div>
                                                            </div>
                                                            <p className="destination-detail__facts-content">
                                                                Kunden von DACHCOM TRAVEL
                                                                haben {this.props.country.name} bereits
                                                                besucht.
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} className="destination-detail__scroll-icon d-none d-md-flex d-xl-none justify-content-center">
                                    <span className="icon-arrow-down-scroll"></span>
                                </Col>
                                <Col xs={12} className="destination-detail__content">
                                    {this.props.children}
                                </Col>
                            </Row>
                            <div className="destination-detail__scroll-icon d-none d-xl-flex justify-content-center">
                                <span className="icon-arrow-down-scroll"></span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </motion.div>
        );
    }
};

DetailDestination.propTypes = {
    addCountToDestination: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    visitedCount: state.destination.visitedCount
});

export default connect(mapStateToProps, {addCountToDestination})(DetailDestination);
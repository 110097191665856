import React from 'react';
import {motion} from "framer-motion";
import {Row, Col, Image} from 'react-bootstrap';
import DetailDestination from './DetailDestination';
import Landmap from './../../../assets/img/landmaps/nulap.png';
import Image1 from './../../../assets/img/nulap/bild1.jpg';
import Image2 from './../../../assets/img/nulap/bild2.jpg';
import Image3 from './../../../assets/img/nulap/bild3.jpg';


const NulapFacts = {
    name: 'Nulap',
    capital: 'Nulap',
    reasonToVisit: 'Nulap ist das Land für Sie, wenn Sie Weihnachten entfliehen möchten – und gleich auch der gesamten modernen Welt.',
    tradition: 'Weihnachten ist den Nulap – die Bewohnerinnen und Bewohner heissen gleich wie das Land selbst – vollkommen egal. Sie kennen auch keinen Kalender, keine Uhren, überhaupt ist ihnen Zeit nicht wichtig. Die gesamten Errungenschaften der modernen Welt sind an den Bergvölkern im winzigen Kleinstaat vorübergegangen. Auch deshalb ist das Land ein unberührtes Paradies geblieben.',
    route: 'nulap',
    landmap: Landmap,
    animationName: 'Nulap',
    animationComposition: '1E1A68DE592D466DB3E0BB210750E41G'
};

const Nulap = () => {
    return (
        <motion.div className="page-wrapper align-center"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 1.5}}>
            <DetailDestination country={NulapFacts}>
                <Row>
                    <Col xs={12}>
                        <h2>Zu den Naturvölkern in Nulap</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Nulap ist ein kleiner, aber zugleich enorm gebirgiger Staat. Auf den
                            Hochebenen und in den einzelnen Tälern leben zahlreiche Volksgruppen, völlig autark und
                            losgelöst vom Rest der Welt. Was auch immer der technologische Fortschritt in den
                            vergangenen Jahrhunderten mit sich brachte, ist in Nulap gänzlich unbekannt. Hier gibt es
                            weder elektrischen Strom noch motorisierte Verkehrsmittel. Dafür lassen sich ungeahnte
                            Naturschönheiten entdecken, und weil die Nulap ein äusserst gastfreundliches Volk sind,
                            fühlt man sich bei einem touristischen Besuch jederzeit wohl und sicher.</p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image1}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Keine Zeit</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-5">Was in der restlichen Welt die unumstössliche Taktgeberin im Alltag
                            ist, findet in Nulap gar nicht statt: Die Zeit ist ein Konstrukt, das im kleinen Land keine
                            Rolle spielt. Der Blick reicht weder in die Vergangenheit noch in die Zukunft, sondern ist
                            stets felsenfest auf die Gegenwart gerichtet. Zwar schwelgen auch die Nulap gerne in
                            Erinnerungen, doch ob diese Erinnerungen sich – nach unserem Verständnis von Zeit – vor
                            zwanzig Minuten oder zwanzig Jahren abgespielt haben, ist ihnen egal. Auch Termine oder
                            Verabredungen sind nicht bekannt, und weil niemand zu einer festgelegten Zeit bestimmte
                            Dinge erledigen oder an definierten Orten sein muss, gibt es auch keine Hektik im Leben der
                            Nulap. Der Rhythmus von Tag und Nacht ist der einzige Takt, dem man im Kleinstaat Folge
                            leistet. Wer sich als Gast darauf einlässt, erlebt eine buchstäblich zeitlose
                            Überraschung.</p>
                    </Col>
                    <Col xs={12}>
                        <h2>Kein Eigentum</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Für Besucher und Besucherinnen der Nulap ist eine Eigenheit der
                            einheimischen Bevölkerung eine nicht zu unterschätzende Herausforderung. Das Prinzip des
                            Besitzens ist nämlich nicht bekannt. Alles gehört allen im Kleinstaat. Zwar leben die Nulap
                            in kleinen Häusern, erheben dabei aber keinen Anspruch darauf, dass es ihnen tatsächlich
                            gehört. Auch Lebensmittel oder Werkzeuge sind niemandes Eigentum, sondern gehören allen
                            Menschen gleichermassen. Wer sein Sandwich, seine Jacke, sein Handy oder andere
                            Habseligkeiten also unbeaufsichtigt liegen lässt, muss damit rechnen, dass sie rasch in
                            andere Hände übergehen. In solchen Fällen hat es auch keinen Zweck, sich lauthals über
                            Diebstahl zu beklagen, denn ein solches Wort existiert in der Sprache der Nulap nicht. </p>
                    </Col>
                    <Col xs={12} className="mb-5 d-flex justify-content-center">
                        <Image fluid src={Image2}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Keine Weihnachten</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">In einem Land, das weder Zeit noch Eigentum kennt, ist natürlich auch
                            Weihnachten kein Thema. Wenn man den Nulap von derartigen Traditionen erzählt, schütteln sie
                            nur verständnislos den Kopf. Wer aber zur Weihnachtszeit gerne ein Fest feiert, ist in Nulap
                            dennoch bestens aufgehoben, denn dem Feiern ist die Bevölkerung niemals abgeneigt. Wenn beim
                            Jagen oder Fischen ein besonders üppiger Fang gelingt, ist ein grosses Festessen garantiert
                            – eingeladen sind alle, die Hunger haben.</p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image3}/>
                    </Col>
                </Row>
            </DetailDestination>
        </motion.div>
    );
};

export default Nulap;
import React from 'react';
import {Container} from 'react-bootstrap'
import {ReactComponent as TravelLogoSVG} from './../../assets/img/dachcom_travel_logo.svg';

const TravelLogo = () => {
    return (
        <Container fluid>
            <div className="travel-logo__wrapper">
                <TravelLogoSVG/>
            </div>
        </Container>
    );
};

export default TravelLogo;
import React from 'react';
import {motion} from "framer-motion";
import {Row, Col, Image} from 'react-bootstrap';
import DetailDestination from './DetailDestination';
import Landmap from './../../../assets/img/landmaps/strizzia.png';
import Image1 from './../../../assets/img/strizzia/bild1.jpg';
import Image2 from './../../../assets/img/strizzia/bild2.jpg';
import Image3 from './../../../assets/img/strizzia/bild3.jpg';


const StrizziaFacts = {
    name: 'Strizzia',
    capital: 'Bellavista',
    reasonToVisit: 'Den Strizziern und Strizzierinnen wird nachgesagt, dass sie das schönste und charmanteste aller Völker seien.',
    tradition: 'In Strizzia geht nichts ohne starke Symbolik und eine gute Portion Pathos. An Weihnachten steigen in allen grösseren Ortschaften unzählige Himmelslaternen auf. Resultat ist ein einzigartiges Schauspiel, das landesweit die Nacht erhellt – eine Nacht, die vor allem in den Tourismus-Hochburgen am Meer fast kein Ende nimmt.',
    route: 'strizzia',
    landmap: Landmap,
    animationName: 'Strizzia',
    animationComposition: '1E1A68DE592D466DB3E0BB210750E41H'
};

const Strizzia = () => {
    return (
        <motion.div className="page-wrapper align-center"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 1.5}}>
            <DetailDestination country={StrizziaFacts}>
                <Row>
                    <Col xs={12}>
                        <h2>Zu den Himmelslaternen nach Strizzia</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Die Küsten von Strizzia sind in ihrer Schönheit unübertroffen.
                            Zerfurchte Felsformationen wechseln sich mit weitläufigen Sandstränden ab, dichte Wälder
                            grenzen an grüne Hügel. An Weihnachten lassen sich die beeindruckenden Landschaften sogar in
                            der Nacht geniessen. Denn in den grösseren Ortschaften des Landes, die sich fast
                            ausschliesslich am Meer befinden, lässt die Bevölkerung nach Anbruch der Dunkelheit
                            unzählige Himmelslaternen aufsteigen. Das hundertausendfache Leuchten der Feuer lässt alles
                            in einem warmen Licht erstrahlen, während die Bevölkerung ausgelassen feiert. Und wenn die
                            Feuer erloschen sind, geht das Fest in den unzähligen Lokalen des Landes weiter.</p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image1}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Der Himmel brennt</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-5">Wer schon immer wissen wollte, wie man die Nacht zum Tag macht, sollte
                            Weihnachten in einer der hübschen Städte an Strizzias Küsten verbringen. Der traditionelle
                            Höhepunkt der Festtage ist nämlich zweifellos die «Assenziunta». Alle Bewohnerinnen und
                            Bewohner bringen eigens angefertigte Himmelslaternen ans Meer. Sobald die Sonne
                            untergegangen ist und die Dunkelheit die Überhand gewonnen hat, werden die Laternen
                            angezündet und auf die Reise geschickt. Überall steigen sie hoch und tauchen die
                            Küstenabschnitte von Strizzia in ein warmes Licht. Ein beeindruckendes Schauspiel – und der
                            Anfang einer langen Nacht.</p>
                    </Col>
                    <Col xs={12}>
                        <h2>Der Bär tanzt</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Nachdem die letzten Feuer der Himmelslaternen erloschen sind und die
                            «Assenziunta» zu Ende ist, geht das Weihnachtsfest in Strizzia in die nächste Runde – und
                            wie. Denn ruhig und besinnlich ist es den Strizzierinnen und Strizziern nicht zumute. In den
                            vielen Lokalen in den Städten am Meer werden die Tische zur Seite geräumt, damit mehr Platz
                            zum Tanzen bleibt. Oftmals spielen Live-Bands mitreissende Rhythmen, die niemanden kalt
                            lassen. Eine aussergewöhnliche und bisweilen etwas befremdliche Tradition ist der
                            «Orsoballo». Eine als Bär verkleidete Person – um wen es sich handelt, wissen nur einige
                            Eingeweihte – betritt die Tanzfläche und beginnt damit, mit allen Anwesenden zu tanzen. Erst
                            im Morgengrauen ist die Feier zu Ende, und der Bär verschwindet wieder.</p>
                    </Col>
                    <Col xs={12} className="mb-5 d-flex justify-content-center">
                        <Image fluid src={Image2}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Das Herz pulsiert</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">In Strizzias Schulen sind «Charme und Romantik» sowie «Liebe und
                            Beziehungen» offizielle Unterrichtsfächer. Dies zeigt den Stellenwert, den amouröse Aspekte
                            im Land geniessen. Den Ruf, das schönste und charmanteste aller Völker zu sein, haben sich
                            die Strizzierinnen und Strizzier redlich verdient. Man schreibt sich Liebesbriefe und
                            Gedichte, legt konsequent Wert auf ein gepflegtes Äusseres und bemüht sich selbst in
                            Langzeitbeziehungen darum, das Feuer der Leidenschaft jeden Tag aufs Neue zu entfachen. Auch
                            für Touristinnen und Touristen kann es eine Herausforderung sein, den Avancen der
                            attraktiven Strizzierinnen und Strizzier zu widerstehen. Wer jedoch mit der Hoffnung auf
                            einen Urlaubsflirt verreist, kann kaum eine bessere Destination wählen als Strizzia.</p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image3}/>
                    </Col>
                </Row>
            </DetailDestination>
        </motion.div>
    );
};

export default Strizzia;
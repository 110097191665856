import {POSTS_SUCCESS, POSTS_ERROR} from "../actions/types";

const initialState = {
    sent: false
};

export default function (state = initialState, action) {
    const {type} = action;

    switch (type) {
        case POSTS_SUCCESS:
            return {
                ...state,
                sent: true
            };
        case POSTS_ERROR:
        default:
            return state;
    }
};
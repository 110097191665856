import React from 'react';
import {motion} from "framer-motion";
import {Row, Col, Image} from 'react-bootstrap';
import DetailDestination from './DetailDestination';
import Landmap from './../../../assets/img/landmaps/kurolia.png';
import Image1 from './../../../assets/img/kurolia/bild1.jpg';
import Image2 from './../../../assets/img/kurolia/bild2.jpg';
import Image3 from './../../../assets/img/kurolia/bild3.jpg';


const KuroliaFacts = {
    name: 'Kurolia',
    capital: 'Treora',
    reasonToVisit: '3807 Dörfer gibt es in Kurolia – und jedes davon hat seinen eigenen Weihnachtsbrauch.',
    tradition: 'In Leloa reiten seltsame Gestalten auf Kamelen von Haus zu Haus. In Frungia werden grosse Leuchtfeuer angezündet. In Budalap tanzt die Bevölkerung um den Dorfbrunnen. In Krintheo lässt man eine grosse Statue abbrennen. Und in Trunghea füllt sich die Luft mit farbigem Rauch. In Kurolia kennt jedes Dorf seine eigene traditionelle Form der Weihnachtsfeier. Bei insgesamt 3807 Dörfern ergibt dies eine unerreichte Vielfalt.',
    route: 'kurolia',
    landmap: Landmap,
    animationName: 'Kurolla',
    animationComposition: '1E1A68DE592D466DB3E0BB210750E41E'
};

const Kurolia = () => {
    return (
        <motion.div className="page-wrapper align-center"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 1.5}}>
            <DetailDestination country={KuroliaFacts}>
                <Row>
                    <Col xs={12}>
                        <h2>Zum tausendfachen Weihnachtsfest nach Kurolia</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">In der kurolischen Sprache gibt es kein Wort für «Stadt» – aus gutem
                            Grund. Selbst die Hauptstadt Treora ist keine eigentliche Stadt, sondern mit ihren rund 4000
                            Einwohnern lediglich das grösste Dorf des Landes. Neben Treora gibt es 3806 weitere kleinere
                            und grössere Siedlungen, die sich zum Teil grundlegend voneinander unterscheiden und nur
                            wenige Gemeinsamkeiten kennen. Auch zu Weihnachten ist Kurolia gewohnt heterogen. Gefeiert
                            wird in jedem Dorf anders. Bei 3807 Weihnachtsbräuchen wird eine Reise in das weitläufige
                            Land zum abwechslungsreichen Erlebnis.</p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image1}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Feuer und Rauch</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-5">Wenn es am Weihnachtsabend in Frungia nicht wirklich dunkel wird, liegt
                            dies an den unzähligen Leuchtfeuern, die im gesamten Dorf brennen. Schon Wochen vor dem Fest
                            beginnen die Bewohnerinnen und Bewohner des Dorfes im Westen des Landes damit, Holz zu
                            sammeln und es zu riesigen Haufen aufzutürmen. Sobald sich die Abenddämmerung ankündigt,
                            werden die Leuchtfeuer angezündet und brennen während der ganzen Nacht. Derweil gehen die
                            Bewohner von Feuer zu Feuer, wärmen sich auf und wünschen sich frohe Weihnachten. Kein
                            Feuer, dafür aber umso mehr Rauch findet sich in Trunghea. Im kleinen Dorf steht Weihnachten
                            ganz im Zeichen der «Smotrops». Dabei handelt es sich um spezielle Stäbe, die nach der
                            Aktivierung farbigen Rauch absondern. Mit den «Smotrops» rennen die Kinder durch das gesamte
                            Dorf und erfüllen die Luft mit roten, blauen, grünen und gelben Rauchschwaden. Wenn sich der
                            Rauch gelegt hat, verschwinden die Menschen in ihren Häusern und feiern Weihnachten im
                            familiären Kreis.</p>
                    </Col>
                    <Col xs={12}>
                        <h2>Tanzbeine und Armbrüste</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">In Budalap steht das Tanzen das ganze Jahr über hoch im Kurs. Jedes
                            Wochenende treffen sich die Dorfbewohner auf Tanzplätzen und lassen die Hüften kreisen.
                            Natürlich machen sie auch an Weihnachten keine Ausnahme und verbringen das Fest zumeist
                            tanzend. Nur hin und wieder machen sie eine kleine Pause, um die traditionellen «Budroggen»
                            zu essen, kleine Teigtaschen, die mit Kartoffeln und Pilzen gefüllt werden. Kaum ist der
                            letzte Bissen geschluckt und üppig mit Rotwein nachgespült, strömen die Budalaperinnen und
                            Budalaper bereits wieder auf die Tanzfläche. Weitaus weniger schwungvoll, aber umso
                            treffsicherer geht Weihnachten in Tellston über die Bühne. Das grosse Armbrustturnier ist im
                            kleinen Dorf im westlichen Teil von Kurolia der krönende Höhepunkt des Jahres. Wer die
                            meisten Punkte erzielt und das Turnier gewinnt, ist dabei jedoch eher zweitrangig. Im
                            Mittelpunkt des Volksfestes steht das gemeinsame Feiern, das oftmals bis weit in die Nacht
                            hinein dauert.</p>
                    </Col>
                    <Col xs={12} className="mb-5 d-flex justify-content-center">
                        <Image fluid src={Image2}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Kamele und Lamas</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Tiere haben in Kurolia einen hohen Stellenwert, und in einigen Dörfern
                            stehen sie in der Weihnachtszeit noch mehr im Mittelpunkt. In Leloa sind es Kamele, die
                            während der Festtage ihren grossen Auftritt haben. In Begleitung von speziell gekleideten
                            Führern werden die Kamele von Haus zu Haus geführt und erhalten überall etwas zu trinken und
                            zu essen. Damit wollen die Dorfbewohnerinnen und Dorfbewohner der Natur für ihre Gaben
                            danken. In Gugliona stehen derweil Lamas zu Weihnachten im Zentrum. Auf dem Dorfplatz werden
                            die schönsten Tiere gezeigt und prämiert. Das Gewinner-Lama aller Klassen wird zum
                            offiziellen Weihnachts-Lama ernannt.</p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image3}/>
                    </Col>
                </Row>
            </DetailDestination>
        </motion.div>
    );
};

export default Kurolia;
import React from 'react';
import {Container} from 'react-bootstrap'
import Guido from './../animations/Guido';
import TravelLogo from "../layout/TravelLogo";
import {motion} from "framer-motion";

const GoodBye = () => {
    return (
        <motion.div className="page-wrapper align-center good-bye"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 1.5}}>
            <TravelLogo/>
            <Container>
                <div className="good-bye__dachcom">
                    <h1>Reisen Sie weiter zu <a href="https://www.dachcom.com/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title="DACHCOM">dachcom.com</a>
                    </h1>
                </div>
            </Container>
            <div></div>
            <div></div>
            <Guido/>
        </motion.div>
    )
};

export default GoodBye;
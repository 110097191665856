import React from 'react';
import {motion} from "framer-motion";
import {Row, Col, Image} from 'react-bootstrap';
import DetailDestination from './DetailDestination';
import Landmap from './../../../assets/img/landmaps/zastavania.png';
import Image1 from './../../../assets/img/zastavania/bild1.jpg';
import Image2 from './../../../assets/img/zastavania/bild2.jpg';
import Image3 from './../../../assets/img/zastavania/bild3.jpg';


const ZastavaniaFacts = {
    name: 'Zastavania',
    capital: 'Yusmaza',
    reasonToVisit: 'Zastavania ist auch als «Land der tausend Flüsse» bekannt – wobei dies eine ziemliche Untertreibung ist.',
    tradition: 'Wie während des restlichen Jahres sind die Zastavas auch zu Weihnachten vornehmlich auf den unzähligen Flüssen des Landes unterwegs. Mit festlich geschmückten kleinen Booten fahren sie über das Wasser, essen und trinken und wünschen sich gegenseitig viel Glück. Wer kein eigenes Boot hat, sitzt an einem der zahlreichen Feuer, die an den Ufern der Flüsse entzündet werden.',
    route: 'zastavania',
    landmap: Landmap,
    animationName: 'Zaszavanien',
    animationComposition: '1E1A68DE592D466DB3E0BB210750E41J'
};

const Zastavania = () => {
    return (
        <motion.div className="page-wrapper align-center"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 1.5}}>
            <DetailDestination country={ZastavaniaFacts}>
                <Row>
                    <Col xs={12}>
                        <h2>Zur Flussfahrt in Zastavania</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Als Binnenland hat Zastavania keinen direkten Zugang zum Meer. Diesen
                            Mangel macht der kleine Staat aber durch eine atemberaubende Flusslandschaft wett. Rund
                            48'000 Flüsse zählt Zastavania. Sie prägen nicht nur die Landschaft, sondern bilden auch die
                            Hauptverkehrswege. Jedes kleinste Dorf ist über mindestens einen Fluss erschlossen, die
                            grösseren Städte wie Ruminova und die Hauptstadt Yusmaza verfügen über ein dicht verzweigtes
                            Netz an Wasserstrassen. An Weihnachten ist auf Zastavanias Flüssen noch mehr los als
                            sonst.</p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image1}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Weihnachten auf dem Fluss</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-5">Die kleinen und grossen Boote auf Zastavanias Flüssen sind das ganze
                            Jahr über ein beeindruckender Anblick. Zu Weihnachten gewinnt dieses Bild aber noch mehr an
                            Reiz. Schon mehrere Tage vor dem Fest beginnen die Zastavas damit, ihre Wasserfahrzeuge zu
                            schmücken und zu verzieren. Am Weihnachtstag werden die Boote schliesslich in Bewegung
                            gesetzt. Während man über das Wasser gleitet, wird gegessen, getrunken und gesungen. Und
                            wenn sich zwei Boote kreuzen – was sehr häufig geschieht –, prosten sich die Passagiere zu
                            und wünschen sich viel Glück. Erst wenn alle Töpfe, Teller, Flaschen und Gläser leer sind,
                            ist die weihnachtliche Flussfahrt zu Ende.</p>
                    </Col>
                    <Col xs={12}>
                        <h2>Nah am Wasser gebaut</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Yusmaza ist nicht nur Hauptstadt von Zastavania, sondern auch das
                            unbestrittene wirtschaftliche und kulturelle Zentrum sowie die älteste Stadt des Landes. Die
                            architektonisch beeindruckenden Bauten in der Stadt liegen allesamt an den unzähligen
                            Flüssen, die Yusmaza gitterartig durchfliessen. Eine Besonderheit sind die «Otosplavas» –
                            kleine Flosse, die wie künstliche Inseln in den Flüssen liegen und zumeist Cafés oder
                            Restaurants beherbergen. Wer will, kann hier eine der unzähligen Kaffeespezialitäten
                            Zastavanias geniessen und dabei das rege Treiben auf dem Fluss beobachten.</p>
                    </Col>
                    <Col xs={12} className="mb-5 d-flex justify-content-center">
                        <Image fluid src={Image2}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Fisch auf den Tisch</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Wer nicht gerne Fisch essen mag, sollte sich eine Reise nach Zastavania
                            besser zweimal überlegen. In einem Land, das fast ausschliesslich aus Flüssen besteht, ist
                            es wohl nur logisch, dass auch die kulinarische Landschaft vom Wasser geprägt ist. Zu jeder
                            Hauptmahlzeit kommt Fisch auf den Tisch, in gekochter, gebratener, frittierter oder auch
                            roher Form. Die vielleicht beliebteste Spezialität des Landes ist «Grugada», eine
                            traditionelle Mischung aus verschiedenen Fischen, Kartoffeln, Weisswein, Fischöl und
                            Kräutern, die stundenlang in einem grossen Topf gekocht wird.</p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image3}/>
                    </Col>
                </Row>
            </DetailDestination>
        </motion.div>
    );
};

export default Zastavania;
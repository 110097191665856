import React from 'react';
import TravelLogo from "../layout/TravelLogo";
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import {motion} from "framer-motion";

const WelcomeReady = () => {
    return (
        <motion.div className="page-wrapper align-center welcome"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 1.5}}>
            <TravelLogo/>
            <div className="content-wrapper">
                <Container fluid className="d-flex justify-content-center">
                    <div className="mw-300">
                        <h1>Sind Sie bereit für Ihre Weihnachtsreise?</h1>
                    </div>
                </Container>
                <Container fluid className="d-flex justify-content-center">
                    <p className="text text-center mw-500">
                        Da dieses Jahr das Reisen vermutlich auch bei Ihnen zu kurz gekommen ist, lädt DACHCOM TRAVEL
                        Sie zu einer virtuellen Weihnachtsreise ein.<br/><br/>
                        Unser Reiseführer Guido wird Sie dabei begleiten.
                    </p>
                </Container>
                <Container fluid className="d-flex justify-content-center">
                    <Link to="/travel-agent" className="btn btn-primary">Reise starten</Link>
                </Container>
            </div>
            <div></div>
        </motion.div>
    )
};

export default WelcomeReady;
import {DESTINATION_SUCCESS, DESTINATION_ERROR} from "../actions/types";

const initialState = {
    visitedCount: 0
};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case DESTINATION_SUCCESS:
            return {
              ...state,
              visitedCount: payload
            };
        case DESTINATION_ERROR:
        default:
            return state;
    }
};
import React from 'react';
import {motion} from "framer-motion";
import {Row, Col, Image} from 'react-bootstrap';
import DetailDestination from './DetailDestination';
import Landmap from './../../../assets/img/landmaps/fouchal.png';
import Image1 from './../../../assets/img/fouchal/bild1.jpg';
import Image2 from './../../../assets/img/fouchal/bild2.jpg';
import Image3 from './../../../assets/img/fouchal/bild3.jpg';


const FouchalFacts = {
    name: 'Fouchal',
    capital: 'Prud’honne',
    reasonToVisit: 'Wer zu Weihnachten möglichst wenig von Weihnachten mitbekommen will, ist in Fouchal genau richtig.',
    tradition: 'Der Inselstaat Fouchal verfügt über eine geradezu verschwenderische Vielfalt an Naturschönheiten. Die Bewohnerinnen und Bewohner der einzelnen Inseln glauben an viele verschiedene Götter und neigen zum Teil zu seltsamen Ritualen – für Weihnachten haben sie jedoch nichts übrig. Das macht Fouchal zur idealen Destination, um dem Weihnachtsstress zu entfliehen.',
    route: 'fouchal',
    landmap: Landmap,
    animationName: 'Fouchal',
    animationComposition: '1E1A68DE592D466DB3E0BB210750E41D'
};

const Fouchal = () => {
    return (
        <motion.div className="page-wrapper align-center"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 1.5}}>
            <DetailDestination country={FouchalFacts}>
                <Row>
                    <Col xs={12}>
                        <h2>Zum weihnachtsfreien Urlaub nach Fouchal</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Wer mit den Menschen in Fouchal über Weihnachten reden will, erntet nur
                            verständnislose Blicke. Im kleinen Inselstaat lebt man polytheistisch, glaubt an viele
                            Götter und praktiziert zahlreiche Rituale – Adventszeit und Weihnachten gehören jedoch nicht
                            dazu. Entsprechend entspannt kann man hier im Dezember Urlaub machen und die faszinierende
                            Landschaft mit ihren zahlreichen Vulkanen, Urwäldern, Klippen und Tälern geniessen.</p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image1}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Im Auge des Vulkans</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-5">Er ist nur einer von vielen Vulkanen der Insellandschaft von Fouchal,
                            doch kein anderer lässt sich so hautnah erleben wie der Mouna Hitaa. Nach der Anreise per
                            Schiff fährt man mit einem der vielen klapprigen Kleinbusse hinauf zum imposanten
                            Hochplateau, das durch seine zerklüftete Gebirgslandschaft bereits ein beeindruckendes
                            Naturschauspiel bietet. Bevor man zu Fuss die Öffnung des Mouna Hitaa erreicht, riecht man
                            ihn bereits. Der unverkennbare Geruch nach faulen Eiern stammt von Schwefelwasserstoff,
                            einem der zahlreichen vulkanischen Gase. Über eine steile Steintreppe steigt man hinab in
                            den Vulkankrater und findet sich auf einer rund zwei Fussballfelder grossen Ebene wieder. An
                            mehreren Stellen treten Dämpfe aus Spalten im Boden, es blubbert und brodelt, der Geruch
                            wird zum Teil sehr intensiv. Weil die Aktivität des Vulkans intensiv überwacht wird, ist ein
                            Besuch des Mouna Hitaa nicht gefährlich – aber ein einzigartiges Erlebnis.</p>
                    </Col>
                    <Col xs={12}>
                        <h2>Einfach abtauchen</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Mindestens so vielfältig wie die Gebirge und Wälder von Fouchal ist
                            auch die Unterwasserwelt des Archipels. Wer abtauchen will, sollte sich aber auf die
                            Unterstützung und Begleitung durch einen der zahlreichen Tauch-Guides verlassen, die sich in
                            praktisch jedem Dorf finden lassen. Zwar gibt es mittlerweile auch vereinzelte männliche
                            Guides, doch aus Tradition ist das Tauchen in Fouchal eine reine Frauensache: Gingen früher die
                            Männer zu Land auf die Jagd, war die Nahrungssuche im Meer den Frauen überlassen. Wer eine
                            geführte Tauch-Tour bucht, darf sich auf eine aussergewöhnliche Unterwasserlandschaft
                            freuen. Neben den zahlreichen Unterwasserpflanzen, Korallen und Gesteinsformationen sind es
                            auch die vielen bunten Meeresbewohner, die jeden Tauchgang zum Erlebnis machen. Fische in
                            allen Farben und Formen, dazu Seepferdchen und die winzigen Zwergdelfine sorgen dafür, dass
                            man unter Wasser in bester Gesellschaft ist.</p>
                    </Col>
                    <Col xs={12} className="mb-5 d-flex justify-content-center">
                        <Image fluid src={Image2}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Den Wald vor lauter Bäumen</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Wer davon ausgeht, dass ein Baum von der Wurzel relativ gerade nach
                            oben wächst, muss sich in Fouchal ein wenig umgewöhnen. In den Urwäldern der Inselgruppe
                            scheinen die Natur und die Schwerkraft neue Gesetze definiert zu haben. Die Bäume wachsen
                            von links nach rechts, bilden skurrile Formationen und lassen merkwürdige Stimmungen
                            entstehen. Nicht selten wachsen zwei Bäume in der Höhe zusammen und verbinden sich zu einem.
                            Ein besonders spektakulärer Waldspaziergang erwartet Besucher der grössten Insel Gran
                            Fouchal, auf der sich auch die Hauptstadt Prud’honne befindet. Der östliche Teil der Insel
                            ist von einem dichten Waldgebiet bedeckt, das sich über zahlreiche Wege erkunden lässt. Auf
                            keinen Fall vergessen sollte man dabei eine gute Portion Insektenspray, denn neben
                            zahlreichen Affenarten, kleinen Raubkatzen und anderen Säugetieren fühlen sich auch Moskitos
                            äusserst wohl in den Wäldern von Fouchal.</p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image3}/>
                    </Col>
                </Row>
            </DetailDestination>
        </motion.div>
    );
};

export default Fouchal;
import React from 'react';
import {motion} from "framer-motion";
import {Row, Col, Image} from 'react-bootstrap';
import DetailDestination from './DetailDestination';
import Landmap from './../../../assets/img/landmaps/bardivien.png';
import Image1 from './../../../assets/img/bardivien/bild1.jpg';
import Image2 from './../../../assets/img/bardivien/bild2.jpg';
import Image3 from './../../../assets/img/bardivien/bild3.jpg';


const BardivienFacts = {
    name: 'Bardivien',
    capital: 'Bondeodorante',
    reasonToVisit: 'In keinem anderen Land duftet es so umwerfend gut wie in Bardivien.',
    tradition: 'Während der Festtage hängen die Bardivierinnen und Bardivier handgefertigte, bunte und rauchende Duftsäcke aus ihren Fenstern. In den sowieso schon wohlriechenden Gassen tun sich in der Folge ungeahnte Duftwelten auf.',
    route: 'bardivien',
    landmap: Landmap,
    animationName: 'Bardivien',
    animationComposition: '1E1A68DE592D466DB3E0BB210750E41B'
};

const Bardivien = () => {
    return (
        <motion.div className="page-wrapper align-center"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 1.5}}>
            <DetailDestination country={BardivienFacts}>
                <Row>
                    <Col xs={12}>
                        <h2>Zum duftenden Weihnachtsbrauch nach Bardivien</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Bardivien ist bekannt für seine Düfte. Sobald man nach der Anreise zum
                            ersten Mal tief durchatmet, wird der Geruchssinn auf eine bisher unbekannte Art und Weise
                            stimuliert. Unzählige Düfte machen eine Reise durch das spärlich besiedelte Land zum
                            olfaktorischen Gesamterlebnis, das zu Weihnachten noch intensiviert wird. Während der
                            Festtage hängen die Bardivierinnen und Bardivier handgefertigte, bunte und rauchende
                            Duftsäcke aus ihren Fenstern. Vor allem in den sowieso schon wohlriechenden Gassen der
                            Hauptstadt Bondeodorante tun sich in der Folge ungeahnte Duftwelten auf, die man nicht so
                            schnell vergisst.</p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image1}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Das Paradies der Parfümeure</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-5">Wer eine feine Nase hat oder in das faszinierende Reich der Düfte
                            eintauchen will, findet in Bardivien ein wahres Paradies. Hier holten sich die
                            erfolgreichsten Parfümeure der Welt wie Alberto Morillas, Nathalie Lorson und François
                            Demachy ihre Inspirationen. Unbestätigte Gerüchte besagen, dass Ernest Beaux, Schöpfer des
                            wohl berühmtestem Parfüms «Chanel Nº 5», im Jahr 1925 einen Versuch startete, einen
                            beträchtlichen Teil des Landes durch die französische Armee einnehmen zu lassen, um
                            unbeschränkten Zugang zur grenzenlosen Duftvielfalt Bardiviens zu erlangen. Weil die ersten
                            militärischen Auskundschafter aber von den olfaktorischen Eindrücken so berauscht waren,
                            dass sie spontan zu überzeugten Pazifisten wurden, verliefen sich die Pläne von Ernest Beaux
                            im Sande. Heute verdankt Bardivien einen beträchtlichen Teil seiner Wirtschaftskraft dem
                            Handel mit Rohstoffen für die Parfümherstellung. Und natürlich dem Tourismus, denn das
                            unvergleichlich duftende Bardivien ist immer eine Reise wert.</p>
                    </Col>
                    <Col xs={12}>
                        <h2>Duftsäcke in Bondeodorante</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">In Bardiviens Hauptstadt Bondeodorante leben rund 800'000 Menschen.
                            Verbrechen sind aber beinahe inexistent, was wohl vor allem daran liegt, dass sich die Leute
                            hier einfach sehr gut riechen mögen. Die Aromenvielfalt im Land erreicht zur Weihnachtszeit
                            einen aussergewöhnlichen Höhepunkt. Ab Mitte Dezember hängen die Bewohnerinnen und Bewohner
                            nämlich bunte Duftsäcke aus ihren Fenstern und erfüllen die Luft mit noch mehr
                            olfaktorischem Zauber. Die Duftsäcke werden in liebevoller Handarbeit aus bunten Tüchern
                            hergestellt und mit den landestypischen Blüten, Kräutern und Gewürzen gefüllt. Für
                            Bardivien-Reisende lohnt sich ein Abstecher in einen der unzähligen Duft-Shops, in welchen
                            die Duftsäcke in Kleinformat gekauft werden können. So hat man auch zu Hause noch ein Hauch
                            Bondeodorante in der Nase.</p>
                    </Col>
                    <Col xs={12} className="mb-5 d-flex justify-content-center">
                        <Image fluid src={Image2}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Immer der Nase nach</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Schon die Araber, Griechen und Römer setzten Pflanzenduftstoffe gezielt
                            für medizinische Zwecke ein. In der Neuzeit war es der französische Chemiker Gattefossé, der
                            überzeugt war, dass Krankheiten mithilfe von ätherischen Ölen behandelt werden können. 1928
                            legte er hierfür den Namen Aromatherapie fest und begründete damit einen neuen Teil der
                            komplementären Medizin. Die Bevölkerung Bardiviens hatte den medizinischen Wert von
                            Duftstoffen aber schon vor vielen Jahrhunderten erkannt. Die vielfältige Natur des Landes
                            hält eine Unmenge an Wirkstoffen bereits, die bei richtiger Anwendung eine erstaunliche
                            Wirkung entfalten können. So kann der Saft der Kronos-Lilie bei wiederholtem Einatmen
                            erblich bedingten Haarausfall bekämpfen, während der Duft des Nolangan-Grases gegen
                            Kopfschmerzen hilft. Verkauft werden die einzelnen Duftstoffe in den unzähligen Aromatheken
                            des Landes, die einen wichtigen Bestandteil der nationalen Gesundheitsversorgung
                            bilden. </p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image3}/>
                    </Col>
                </Row>
            </DetailDestination>
        </motion.div>
    );
};

export default Bardivien;
import React from 'react';
import {motion} from "framer-motion";
import {Row, Col, Image} from 'react-bootstrap';
import DetailDestination from './DetailDestination';
import Landmap from './../../../assets/img/landmaps/andruvien.png';
import Image1 from './../../../assets/img/andruvien/bild1.jpg';
import Image2 from './../../../assets/img/andruvien/bild2.jpg';
import Image3 from './../../../assets/img/andruvien/bild3.jpg';


const AndruvienFacts = {
    name: 'Andruvien',
    capital: 'Kroga',
    reasonToVisit: 'Wenn Sie schon immer wissen wollten, wie pure Lebensfreude aussieht, ist Andruvien der perfekte Ort.',
    tradition: 'Am Jahresende steht in Andruvien das traditionelle Jelle-Fest an. Die sowieso schon feier- und tanzfreudige Bevölkerung Andruviens feiert und tanzt dabei noch ausgelassener – in den wahrscheinlich fantasievollsten Verkleidungen der modernen Welt.',
    route: 'andruvien',
    landmap: Landmap,
    animationName: 'Andruvien',
    animationComposition: '1E1A68DE592D466DB3E0BB210750E41A'
};

const Andruvien = () => {
    return (
        <motion.div className="page-wrapper align-center"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 1.5}}>
            <DetailDestination country={AndruvienFacts}>
                <Row>
                    <Col xs={12}>
                        <h2>Zum grossen Jelle-Fest nach Andruvien</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Es gibt wohl kaum ein Volk, das seine Feste so ausgelassen und fröhlich
                            zu
                            feiern weiss wie die Andruvierinnen und Andruvier. Zu Weihnachten treibt es Andruviens
                            Bevölkerung besonders bunt. Denn dann steht das traditionelle Jelle-Fest an. Das andruvische
                            Wort «Jelle» lässt sich übrigens mit «grosses, rotes und pochendes Gefühl in der Brust»
                            übersetzen – und zur Weihnachtszeit ist dieses Gefühl so gross und so rot und so pochend,
                            dass die Andruvierinnen und Andruvier kaum mehr mit dem Tanzen und Feiern aufhören
                            mögen.</p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image1}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Jelle – Das grösste Fest des Jahres</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-5">Stellen Sie sich die ausgelassenste Geburtstagsfeier oder einen
                            fröhlichen Maskenball vor. Multiplizieren Sie diese Stimmung mit dem Faktor 20. Das Resultat
                            ist zumindest annähernd vergleichbar mit der Intensität des Jelle-Festes in Andruvien. Ob im
                            kleinen Dorf oder in den grossen Städten – zur Weihnachtszeit erreicht die traditionell
                            schon sehr grosse Feierlaune der Andruvierinnen und Andruvier den unangefochtenen Höhepunkt.
                            Schon am 23. Dezember beginnt das viertägige Fest, wenn auch eher unkonventionell für
                            andruvische Verhältnisse, denn am Anfang des Jelle-Festes steht ein Abend der Stille. In
                            grossen und kleinen Gruppen ziehen die Andruvierinnen und Andruvier durch Strassen und
                            Gassen, ganz in Weiss gekleidet, mit Papierlaternen in den Händen – aber komplett still.
                            Keine Musik und keine Stimmen sind zu hören. Die schweigende Prozession bietet ein
                            beeindruckendes Schauspiel, vor allem, wenn die einzelnen Gruppen auf zentralen Plätzen
                            zusammenfinden und ein Lichtermeer entstehen lassen. Am 24. Dezember ist von der
                            Schweigsamkeit des Vorabends nichts mehr zu spüren. Schon zur Mittagszeit nehmen die
                            traditionellen Jelle-Orchester ihre Plätze in gut frequentierten Fussgängerzonen ein und
                            beginnen zu spielen. Vor allem in grösseren Orchestern sind die einzelnen Instrumente
                            doppelt vertreten – wenn ein Musiker müde ist, wird er vom zweiten abgelöst. Auf diese Weise
                            können die Orchester ohne Pause durchspielen. Die Musik scheint derweil direkt in die Körper
                            der Passanten zu fliessen, denn wer am 24. Dezember auf Andruviens Gassen unterwegs ist –
                            und es dürfte wenige geben, die es nicht sind –, der tanzt zum pulsierenden Rhythmus der
                            Jelle-Orchester. Am Weihnachtstag sind die Strassen etwas weniger dicht bevölkert, denn der
                            25. Dezember ist in vielen Familien für die Weihnachtsfeier zu Hause reserviert. Getanzt
                            wird aber auch dort. Am 26. Dezember finden sich dann wieder alle auf den Strassen ein, wo
                            die viertägige Weihnachtsfeier mit einem grossen Feuerwerk ihr Ende findet. </p>
                    </Col>
                    <Col xs={12}>
                        <h2>Die Sirenen von Kaltrapolli</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Andruviens Geschichte ist so eng mit Musik und Gesang verknüpft, dass
                            sogar die Geschichtsbücher in den Schulen mit Musiknoten versehen sind. Einen besonderen
                            Stellenwert haben dabei die Sirenen von Kaltrapolli, einer kleinen Hafenstadt im Süden des
                            Landes. Den Sirenen der griechischen Mythologie nicht ganz unähnlich, waren auch die Sirenen
                            von Kaltrapolli weibliche Fabelwesen, die sich durch ihren betörenden Gesang auszeichneten.
                            Doch während ihre griechischen Entsprechungen die vorbeifahrenden Seefahrer anlockten, um
                            sie zu töten, hatten die Sirenen von Kaltrapolli ungleich freundlichere Absichten. Sie
                            wollten vor allem singen. Die Männer, die von ihnen angelockt wurden, sollten deshalb nicht
                            sterben, sondern die Bassstimmen im Chor übernehmen. So schufen die Sirenen von Kaltrapolli
                            zusammen mit ihren männlichen «Opfern» einige der wohl schönsten gesanglichen Darbietungen
                            der Weltgeschichte.</p>
                    </Col>
                    <Col xs={12} className="mb-5 d-flex justify-content-center">
                        <Image fluid src={Image2}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Die Wandbilder von Kroga</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Kroga ist nicht nur die Hauptstadt von Andruvien, sondern auch der
                            kulturelle Mittelpunkt des Landes. Unübersehbares Highlight und omnipräsent in den Gassen
                            sind die Wandbilder, die weite Teile der Altstadt zieren. Einige sind bereits mehrere
                            hundert Jahre alt, doch dank intensiver Pflege haben sie nichts von ihrer Farbenpracht und
                            Dynamik verloren. Die Wandbilder – von der Stadtbevölkerung liebevoll «Skizzas» (Skizzen)
                            genannt – führen in oftmals witziger und augenzwinkernder Weise die Lebensfreude der
                            Bevölkerung Andruviens vor Augen. </p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image3}/>
                    </Col>
                </Row>
            </DetailDestination>
        </motion.div>
    );
};

export default Andruvien;
import React from 'react';
import {motion} from "framer-motion";
import {Row, Col, Image} from 'react-bootstrap';
import DetailDestination from './DetailDestination';
import Landmap from './../../../assets/img/landmaps/mondovien.png';
import Image1 from './../../../assets/img/mondovien/bild1.jpg';
import Image2 from './../../../assets/img/mondovien/bild2.jpg';
import Image3 from './../../../assets/img/mondovien/bild3.jpg';


const MondovienFacts = {
    name: 'Mondovien',
    capital: 'Putras',
    reasonToVisit: 'Zu Weihnachten bringt der weise Putras der Bevölkerung Glück und eine ziemlich eklige Spezialität.',
    tradition: 'In Mondovien steht Weihnachten ganz im Zeichen des weisen Putras. Um dem ruhmreichen Gelehrten ihre Ehre zu erweisen, feiert die Bevölkerung ein grosses Fest, dessen kulinarischer Höhepunkt eine Herausforderung für jeden ungeübten Gaumen ist: Das Putrasbrot, das an Weihnachten auf den Tisch kommt, hat es in sich.',
    route: 'mondovien',
    landmap: Landmap,
    animationName: 'Mondovien',
    animationComposition: '1E1A68DE592D466DB3E0BB210750E41F'
};

const Mondovien = () => {
    return (
        <motion.div className="page-wrapper align-center"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 1.5}}>
            <DetailDestination country={MondovienFacts}>
                <Row>
                    <Col xs={12}>
                        <h2>Zum weisen Putras nach Mondovien</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Die ganze Geschichte Mondoviens ist eng mit Weihnachten verknüpft. Eine
                            Legende besagt, dass ein Gelehrter namens Putras in einer kalten Winternacht unterwegs war.
                            Dabei stolperte er über seinen langen Bart und stiess sich den Kopf an. In der Folge hatte
                            er eine Vision einer glorreichen Zukunft, in der kein Mensch mehr Hunger leiden oder
                            Nachteile in Kauf nehmen muss. Zwar hat sich diese Vision (noch) nicht erfüllt, doch die
                            Mondovierinnen und Mondovier haben Weihnachten dennoch zum offiziellen Feiertag in Gedenken
                            an Putras erkoren und begehen zu diesem Anlass ein grosses Fest – mit fragwürdigen
                            kulinarischen Köstlichkeiten. </p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image1}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Beim Barte des Propheten</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-5">Putras war ein bekannter Gelehrter im frühzeitlichen Mondovien. Mit
                            seinen klugen Beobachtungen und teilweise sehr gewagten Theorien schuf er sich ein Ansehen,
                            das bis in die Gegenwart strahlt. Die Leitsätze von Putras prägen das Leben in Mondovien
                            noch heute und gelten als nationales Kulturgut. Eines der bekanntesten Elemente des
                            Schaffens von Putras ist zweifellos seine Weihnachtsvision. In einer kalten Nacht war der
                            alte Mann allein unterwegs, seine üppige Bartpracht, die bis zum Boden reichte, hatte er wie
                            einen wärmenden Schal um den Hals geschlungen. Irgendwann löste sich der Bart jedoch, und
                            bevor er reagieren konnte, trat Putras auf das Ende des Bartes und fiel hin. Bei seinem
                            Sturz stiess er sich heftig seinen Kopf an einem Stein und wurde für kurze Zeit bewusstlos.
                            Noch während seiner Ohnmacht oder direkt nach dem Aufwachen – die Geschichtsschreibung ist
                            diesbezüglich nicht sonderlich präzise – hatte Putras eine Vision. Er sah ein Mondovien, in
                            dem kein Mensch mehr Hunger leiden musste, niemand mehr wirtschaftliche oder
                            gesellschaftliche Nachteile hatte und es in keinem Haushalt an grundlegenden Dingen
                            mangelte. Nachdem er sich aufgerappelt und seinen Bart wieder hübsch drapiert hatte, rannte
                            Putras ins nächstgelegene Dorf und verkündete, was er gesehen hatte. Seine Vision
                            verbreitete sich rasend schnell und erfüllte die Mondovierinnen und Mondovier mit einer
                            unstillbaren Hoffnung auf den Tag, an dem sie Realität werden würde. Zwar wartet man darauf
                            noch heute. Dennoch feiert man jedes Jahr zu Weihnachten ein grosses Fest, um Putras und
                            seine Vision zu würdigen. </p>
                    </Col>
                    <Col xs={12}>
                        <h2>Brot für alle</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Das Weihnachtsfest in Mondovien ist auch in kulinarischer Hinsicht eng
                            mit dem weisen Putras verknüpft. Um den bekannten Gelehrten und seine Weihnachtsvision zu
                            würdigen, kommt zu Weihnachten das sogenannte Putrasbrot auf den Tisch. Das Gebäck
                            beinhaltet neben Honig, Eiern und Mehl auch noch jede Menge weiterer Zutaten, die nur selten
                            Eingang in Kochbücher finden – zum Beispiel Birkenblätter, Morgentau und geröstete
                            Tannennadeln. Die entscheidende Zutat ist wohl zugleich auch die merkwürdigste: Zu Ehren des
                            Bartes des Propheten muss sich der älteste Bartträger der Familie von mindestens vier
                            Dutzend Barthaaren trennen, die ebenfalls dem Brotteig zugefügt werden. Das gebackene Brot
                            wird am Ende unter allen Familienmitgliedern aufgeteilt und gemeinsam verspeist.</p>
                    </Col>
                    <Col xs={12} className="mb-5 d-flex justify-content-center">
                        <Image fluid src={Image2}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Barfuss in der Stadt</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Eine weitere Anekdote aus dem Leben des weisen Putras betrifft seine
                            Füsse, denn der grosse Gelehrte pflegte sie unbedeckt zu lassen. Selbst im tiefsten Winter
                            war er barfuss unterwegs, weil er überzeugt war, dass dies der Durchblutung seiner
                            Gliedmassen zuträglich sei. Eine Überlieferung besagt, dass er nach einer eisigen Nacht den
                            grössten Platz der grössten Stadt des Landes von Eis befreite, indem er es mit seinen warmen
                            Fusssohlen zum Schmelzen brachte. Diese Episode beeindruckte die Mondovierinnen und
                            Mondovier so sehr, dass sie nicht nur den Platz, sondern auch die Stadt nach Putras
                            benannten und sie zur Hauptstadt machten.</p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image3}/>
                    </Col>
                </Row>
            </DetailDestination>
        </motion.div>
    );
};

export default Mondovien;
import React from 'react';
import {motion} from "framer-motion";
import {Row, Col, Image} from 'react-bootstrap';
import DetailDestination from './DetailDestination';
import Landmap from './../../../assets/img/landmaps/ubia.png';
import Image1 from './../../../assets/img/ubia/bild1.jpg';
import Image2 from './../../../assets/img/ubia/bild2.jpg';
import Image3 from './../../../assets/img/ubia/bild3.jpg';


const UbiaFacts = {
    name: 'Ubia',
    capital: 'Krolla',
    reasonToVisit: 'Die Ubieterinnen und Ubieter machen alles ein bisschen anders als die anderen – neue Erfahrungen sind garantiert.',
    tradition: 'In Ubia ist alles ein wenig anders als in den übrigen Ländern. Hier wird an Weihnachten nicht beschenkt, sondern bestohlen. Anstelle eines Baumes schmückt man grosse Erdhügel, die vor den Häusern aufgeschichtet werden. Und zum Fest wird kein Weihnachtsbraten serviert, sondern Tintenfisch.',
    route: 'ubia',
    landmap: Landmap,
    animationName: 'Ubia',
    animationComposition: '1E1A68DE592D466DB3E0BB210750E41I'
};

const Ubia = () => {
    return (
        <motion.div className="page-wrapper align-center"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 1.5}}>
            <DetailDestination country={UbiaFacts}>
                <Row>
                    <Col xs={12}>
                        <h2>Zur verkehrten Welt nach Ubia</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Wer den Eindruck hat, der Alltag sei zu stark von Normalität geprägt,
                            findet in Ubia ein konsequentes Kontrastprogramm. Die Ubieterinnen und Ubieter geben sich
                            grösste Mühe, die Dinge ganz anders zu machen als alle anderen. Auf den Strassen fährt man
                            rückwärts, gearbeitet wird in der Nacht, man trinkt den Kaffee kalt und den Orangesaft warm.
                            Natürlich werden auch zu Weihnachten viele Konventionen umgekehrt. Weihnachtsgeschenke und
                            Weihnachtsbraten gibt es nicht – stattdessen wird gestohlen und Tintenfisch gegessen.</p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image3}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Achtarmige Spezialität</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-5">Während hierzulande zum Weihnachtsfest oftmals ein üppiger Braten oder
                            ein saftiger Schinken auf den Tisch kommt, haben die Ubieterinnen und Ubieter eine andere
                            Spezialität zum offiziellen Festessen auserkoren: Oktopus. Meistens werden die ganzen
                            Tintenfische im Salzwasser gekocht, bisweilen auch gegrillt oder im Ofen zubereitet. Wichtig
                            ist dabei, dass der Oktopus stets unzerteilt auf den Tisch kommt und erst dann in einzelne
                            Portionen geschnitten wird. Dazu wird meistens Vanillepudding serviert, was zwar unpassend
                            klingt, aber erstaunlich gut mit dem Geschmack des Tintenfisches harmoniert. </p>
                    </Col>
                    <Col xs={12}>
                        <h2>Diebstahl als Kulturgut</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Die Ubieterinnen und Ubieter sind grundsätzlich ein sehr ehrliches und
                            rechtschaffenes Volk, Raub und Diebstahl kommen kaum vor. An Weihnachten jedoch verwandelt
                            sich das ganze Land in eine Ansammlung von Kleptomanen. Wer sich gewohnt ist, zum Fest
                            Geschenke unter den geschmückten Weihnachtsbaum zu legen, muss in Ubia fundamental umdenken.
                            Einerseits, weil es im ganzen Land keine Christbäume gibt und stattdessen grosse Erdhügel
                            aufgeschichtet und geschmückt werden. Andererseits machen die Ubieterinnen und Ubieter keine
                            Weihnachtsgeschenke, sondern frönen der jahrhundertealten Tradition des
                            Weihnachtsdiebstahls. An Heiligabend verschafft man sich Zutritt zu fremden Häusern und
                            entwendet drei bestimmte Objekte: eine Flasche Wein, eine Wolldecke und eine Goldmünze. </p>
                    </Col>
                    <Col xs={12} className="mb-5 d-flex justify-content-center">
                        <Image fluid src={Image2}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Verkehr verkehrt</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Wer Ubia mit einem Mietwagen bereisen will, braucht Mut und ein wenig
                            Übung. Denn auf allen Strassen im Land läuft der Verkehr generell verkehrt. Autos, Busse und
                            LKWs sind im Rückwärtsgang unterwegs, und wer links abbiegen will, blinkt rechts. Die
                            Einheimischen manövrieren ihre Fahrzeuge mit bewundernswerter Sicherheit – falls es in Ubia
                            zu Verkehrsunfällen kommt, sind in den meisten Fällen Touristinnen und Touristen aus dem
                            Ausland involviert.</p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image1}/>
                    </Col>
                </Row>
            </DetailDestination>
        </motion.div>
    );
};

export default Ubia;
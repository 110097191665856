import React from 'react';
import {motion} from "framer-motion";
import {Row, Col, Image} from 'react-bootstrap';
import DetailDestination from './DetailDestination';
import Landmap from './../../../assets/img/landmaps/duenebarg.png';
import Image1 from './../../../assets/img/duenebarg/bild1.jpg';
import Image2 from './../../../assets/img/duenebarg/bild2.jpg';
import Image3 from './../../../assets/img/duenebarg/bild3.jpg';


const DuenebargFacts = {
    name: 'Dünebarg',
    capital: 'Feldhagen',
    reasonToVisit: 'Die Weihnachtsmärkte in Feldhagen sind nicht nur wegen des heissen Rums eine Reise wert.',
    tradition: 'Das kleine Dünebarg ist in vielerlei Hinsicht betont zukunftsorientiert, beweist Innovationsgeist und Entwicklungsfreude. Zu Weihnachten stehen aber Traditionen an erster Stelle. Auf den Weihnachtsmärkten in Feldhagen und den anderen Städten des Landes erwachen diese Traditionen zum Leben.',
    route: 'duenebarg',
    landmap: Landmap,
    animationName: 'Duenebarg',
    animationComposition: '1E1A68DE592D466DB3E0BB210750E41C'
};

const Duenebarg = () => {
    return (
        <motion.div className="page-wrapper align-center"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 1.5}}>
            <DetailDestination country={DuenebargFacts}>
                <Row>
                    <Col xs={12}>
                        <h2>Zum fröhlichen Markttreiben nach Dünebarg</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">In Sachen Weihnachtsmärkte bietet wohl kein anderes Land so viel
                            Atmosphäre, Tradition und Vielfalt wie Dünebarg. In der Hauptstadt Feldhagen und in anderen
                            grösseren Städten entstehen am Jahresende gänzlich neue Welten. Auf den Weihnachtsmärkten
                            warten nicht nur zahlreiche Verkaufsstände, kulinarische Köstlichkeiten und wärmende
                            Getränke auf die Besucherinnen und Besucher, sondern auch eine einzigartige Stimmung.</p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image1}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Kalte Nächte und heisser Rum in Feldhagen</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-5">Während des Jahres freuen sich die Touristen in Dünebargs Hauptstadt
                            Feldhagen über die beeindruckende Architektur im historischen Stadtkern, über die charmanten
                            Einkaufsläden, über die unzähligen kleinen Cafés und Bistros. Zum Jahresende verwandelt sich
                            die Stadtmitte aber zu einem einzigen grossen Weihnachtsmarkt. Auf jedem grösseren Platz
                            werden Marktstände aufgestellt, dazwischen Karussells für die Kinder, und auf dem
                            Kronen-Platz im Herzen Feldhagens entsteht sogar ein riesiges Eisfeld, auf welchem die
                            Stadtbevölkerung ihre Leidenschaft für das Eislaufen ausleben kann. Eine besondere
                            Spezialität der Dünebarger Weihnachtsmärkte ist zweifellos der «Brändenromma». Die Mischung
                            aus heissem Rum, Zucker und diversen Gewürzen geht auf schnellstem Weg ins Blut und macht
                            selbst die kältesten Temperaturen erträglich.</p>
                    </Col>
                    <Col xs={12}>
                        <h2>Eisskulpturen im Königspalast</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Zwar hat Dünebarg die Monarchie vor rund zwanzig Jahren offiziell
                            abgeschafft, doch der Königspalast in Feldhagen ist auch weiterhin ein wichtiges Wahrzeichen
                            und beliebter Treffpunkt für Touristen und Einheimische gleichermassen. Blüht hier im Sommer
                            eine beeindruckende Parklandschaft mit Blumen, Sträuchern und Bäumen, wird das Areal im
                            Dezember von ganz anderen Kreaturen bevölkert. Man trifft auf Elvis Presley und Marilyn
                            Monroe, auf Mammut und Tyrannosaurus Rex, auf Engel und Teufel. Grund zur Sorge muss man
                            dabei aber nicht haben, denn sämtliche Figuren sind aus Eis gefertigt. Das alljährliche
                            Eisskulpturen-Festival im Königspalast dauert vier Wochen und bietet Künstlern aus aller
                            Welt eine ideale Plattform, um ihr Können eiskalt unter Beweis zu stellen.</p>
                    </Col>
                    <Col xs={12} className="mb-5 d-flex justify-content-center">
                        <Image fluid src={Image2}/>
                    </Col>
                    <Col xs={12}>
                        <h2>Dieses Dorf hat Zukunft</h2>
                    </Col>
                    <Col xs={12}>
                        <p className="text mb-4">Gnutbergen liegt rund 50 Kilometer von Dünebargs Hauptstadt Feldhagen
                            entfernt. Doch wer die Strecke zurücklegt, kommt buchstäblich in der Zukunft an. Denn das
                            kleine Dorf Gnutbergen ist kein Dorf im eigentlichen Sinne, sondern eher ein Experiment.
                            Hier werden seit einigen Jahren innovative Wohn- und Lebenskonzepte sowie visionäre
                            gesellschaftliche Strukturen erprobt. Ob bedingungsloses Grundeinkommen, spezielle
                            Ernährungsformen oder autonomes Fahren – was anderswo noch Zukunftsmusik ist, stellt hier
                            bereits einen integralen Teil des Alltags dar. Zu Beginn lebten in Gnutbergen gerade einmal
                            100 Personen, doch das Dorf ist schnell gewachsen und beheimatet heute bereits rund 8000
                            Menschen, die je nach Präferenz in Einfamilienhäusern, in grösseren Wohnblöcken oder in
                            mobilen Gebäudeeinheiten wohnen. Was nach einem schlechten Science-Fiction-Film klingt, ist
                            in Wahrheit ein faszinierender Einblick in die Welt von morgen. </p>
                    </Col>
                    <Col xs={12} className="mb-4 d-flex justify-content-center">
                        <Image fluid src={Image3}/>
                    </Col>
                </Row>
            </DetailDestination>
        </motion.div>
    );
};

export default Duenebarg;
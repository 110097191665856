import React from 'react';
import SpeakBubble from './../layout/SpeakBubble';
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import {motion} from "framer-motion";
import TravelLogo from "../layout/TravelLogo";
import Guido from './../animations/Guido';

const Donation = () => {
    return (
        <motion.div className="page-wrapper align-center thank-you"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 1.5}}>
            <TravelLogo/>
            <Container className="d-flex justify-content-center flex-column align-items-center">
                <SpeakBubble>
                    <h1 className="text-left">Spende</h1>
                    <p className="text--small mb-4">
                        PS: Aus Tradition und Überzeugung versendet DACHCOM keine Weihnachtsgeschenke. Stattdessen
                        unterstützen wir die Stiftung «Médecins Sans Frontières - Ärzte ohne Grenzen Schweiz» mit einer
                        Spende.<br/>
                        Für die angenehme Zusammenarbeit in diesem merkwürdigen Jahr danken wir Ihnen von Herzen.
                    </p>
                    <Guido/>
                </SpeakBubble>
                <Link to="/good-bye" className="thank-you__continue-link mt-4 icon-arrow-right"></Link>
            </Container>
            <div></div>
        </motion.div>
    )
};

export default Donation;
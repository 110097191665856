import React, {useState} from 'react';
import AnimateCC from "react-adobe-animate";

const Guido = () => {
    const [, setAnimationObject] = useState(null);
    const getAnimationObject = obj => setAnimationObject(obj);

    return (
        <div className="animation animation-guido">
            <AnimateCC
                animationName="Guido"
                composition="79BE90838A3743C78C01C92F5420C0B7"
                getAnimationObject={getAnimationObject}
            />
        </div>
    );
};

export default Guido;

import React from 'react';
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import {setAuthToken} from "./utils/setAuthToken";

//REDUX
import {Provider} from 'react-redux';
import store from './store';

//PAGES
import Destinations from './components/pages/Destinations';
import Donation from './components/pages/Donation';
import GoodBye from './components/pages/GoodBye';
import Login from './components/pages/Login';
import Message from './components/pages/Message';
import ThankYou from './components/pages/ThankYou';
import TravelAgent from './components/pages/TravelAgent';
import Welcome from './components/pages/Welcome';
import WelcomeReady from './components/pages/WelcomeReady';
import Andruvien from './components/pages/destinations/Andruvien';
import Bardivien from './components/pages/destinations/Bardivien';
import Duenebarg from './components/pages/destinations/Duenebarg';
import Fouchal from './components/pages/destinations/Fouchal';
import Kurolia from './components/pages/destinations/Kurolia';
import Mondovien from './components/pages/destinations/Mondovien';
import Nulap from './components/pages/destinations/Nulap';
import Strizzia from './components/pages/destinations/Strizzia';
import Ubia from './components/pages/destinations/Ubia';
import Zastavania from './components/pages/destinations/Zastavania';
import Loading from './components/pages/destinations/Loading';


if (localStorage.token) {
    setAuthToken(localStorage.token);
}

const App = () => {
    return (
        <Provider store={store}>
            <Router>
                <AnimatePresence exitBeforeEnter>
                    <Switch>
                        <Route exact path='/' component={Login}/>
                        <Route exact path='/welcome' component={Welcome}/>
                        <Route exact path='/welcome/ready' component={WelcomeReady}/>
                        <Route exact path='/travel-agent' component={TravelAgent}/>
                        <Route exact path='/destinations' component={Destinations}/>
                        <Route exact path='/destinations/andruvien' component={Andruvien}/>
                        <Route exact path='/destinations/bardivien' component={Bardivien}/>
                        <Route exact path='/destinations/duenebarg' component={Duenebarg}/>
                        <Route exact path='/destinations/fouchal' component={Fouchal}/>
                        <Route exact path='/destinations/kurolia' component={Kurolia}/>
                        <Route exact path='/destinations/mondovien' component={Mondovien}/>
                        <Route exact path='/destinations/nulap' component={Nulap}/>
                        <Route exact path='/destinations/strizzia' component={Strizzia}/>
                        <Route exact path='/destinations/ubia' component={Ubia}/>
                        <Route exact path='/destinations/zastavania' component={Zastavania}/>
                        <Route exact path='/destinations/loading/:redurl' component={Loading}/>
                        <Route exact path='/message' component={Message}/>
                        <Route exact path='/thank-you' component={ThankYou}/>
                        <Route exact path='/donation' component={Donation}/>
                        <Route exact path='/good-bye' component={GoodBye}/>
                    </Switch>
                </AnimatePresence>
            </Router>
        </Provider>
    );
};

export default App;

import axios from 'axios';
import {DESTINATION_SUCCESS, DESTINATION_ERROR} from "./types";

//Add 1 to visitedCount
export const addCountToDestination = (destinationName) => async dispatch => {
    try {
        const res = await axios.post('/api/destinations/' + destinationName);

        dispatch({
            type: DESTINATION_SUCCESS,
            payload: res.data
        });

    } catch (err) {
        console.log(err);
        dispatch({
            type: DESTINATION_ERROR
        })
    }
};
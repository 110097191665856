import {combineReducers} from "redux/es/redux";

import auth from './auth';
import destination from './destination';
import posts from './posts';
import user from './user';

export default combineReducers({
    auth,
    destination,
    posts,
    user
});
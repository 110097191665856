import React from "react";
import {motion} from "framer-motion";
import Tilt from 'react-tilt'
import {SlideDown} from "react-slidedown";
import {Container, Row, Col} from "react-bootstrap";
import TravelLogo from "../layout/TravelLogo";
import Guido from "../animations/Guido";
import SpeakBubble from "../layout/SpeakBubble";

import Raster from './../../assets/img/map_raster.png';
import Countries from './../../assets/img/map_countries.png';
import AndruvienMap from './../../assets/img/map_andruvien.png';
import BardivienMap from './../../assets/img/map_bardivien.png';
import DuenebargMap from './../../assets/img/map_duenebarg.png';
import FouchalMap from './../../assets/img/map_fouchal.png';
import KuroliaMap from './../../assets/img/map_kurolia.png';
import MondovienMap from './../../assets/img/map_mondovien.png';
import NulapMap from './../../assets/img/map_nulap.png';
import StrizziaMap from './../../assets/img/map_strizzia.png';
import UbiaMap from './../../assets/img/map_ubia.png';
import ZastavaniaMap from './../../assets/img/map_zastavania.png';
import {Link} from "react-router-dom";

class Destinations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeDest: {
                name: '',
                route: '',
                image: null,
                slogan: ''
            },
            selectStatus: false,
            date: new Date()
        };
    }


    selectableDestinations = [
        {
            name: 'Andruvien',
            route: 'andruvien',
            image: AndruvienMap,
            slogan: 'Jelle feiern in '
        },
        {
            name: 'Bardivien',
            route: 'bardivien',
            image: BardivienMap,
            slogan: 'Dufterlebnis in '
        },
        {
            name: 'Dünebarg',
            route: 'duenebarg',
            image: DuenebargMap,
            slogan: 'Kalte Nächte und heisser Rum in '
        },
        {
            name: 'Fouchal',
            route: 'fouchal',
            image: FouchalMap,
            slogan: 'Ohne Weihnachtsstress in '
        },
        {
            name: 'Kurolia',
            route: 'kurolia',
            image: KuroliaMap,
            slogan: '3807 Weihnachtsbräuche in '
        },
        {
            name: 'Mondovien',
            route: 'mondovien',
            image: MondovienMap,
            slogan: 'Hoher Besuch in '
        },
        {
            name: 'Nulap',
            route: 'nulap',
            image: NulapMap,
            slogan: 'Zeitlos in '
        },
        {
            name: 'Strizzia',
            route: 'strizzia',
            image: StrizziaMap,
            slogan: 'Höhenflüge in '
        },
        {
            name: 'Ubia',
            route: 'ubia',
            image: UbiaMap,
            slogan: 'Alles anders in '
        },
        {
            name: 'Zastavania',
            route: 'zastavania',
            image: ZastavaniaMap,
            slogan: 'Mit dem Boot durch '
        }
    ];

    selectDestinationHandler = (dest) => {
        this.resetSelectionDestination();
        this.setState({
            activeDest: dest,
            selectStatus: true
        });
    };

    resetSelectionDestination = () => {
        this.setState({
            activeDest: {
                name: '',
                route: '',
                image: null,
                slogan: ''
            },
            selectStatus: false
        });
    };

    destinationsMapped = this.selectableDestinations.map((dest) => {
        return (
            <button key={dest.name} onClick={() => this.selectDestinationHandler(dest)}
                 className="destinations__selection-link">
                {dest.name}
            </button>
        );
    });

    render() {
        const destinationBubbleSelected = (
            <SpeakBubble>
                <h1 className="text-left mb-4 mb-md-2">Ihr Reiseplan</h1>
                <div className="time-element">
                    <div className="time-element__heading">
                        <p className="text--small text-left">
                            Abreise nach {this.state.activeDest.name} um
                        </p>
                    </div>
                    <div className="time-element__time">
                        {(String(this.state.date.getHours()).padStart(2, '0')) + ':' + (String(this.state.date.getMinutes()).padStart(2, '0')) + ' Uhr'}
                    </div>
                </div>
                <div className="time-element">
                    <div className="time-element__heading">
                        <p className="text--small text-left">
                            Ankunft um
                        </p>
                    </div>
                    <div className="time-element__time">
                        {(String(Math.floor(Math.random() * 24)).padStart(2, '0')) + ':' + (String(Math.floor(Math.random() * 60)).padStart(2, '0')) + ' Uhr'}
                    </div>
                </div>
                <div className="d-flex btn-container">
                    <div className="btn" onClick={this.resetSelectionDestination}>Zurück</div>
                    <Link to={'/destinations/loading/' + this.state.activeDest.route}
                          className="btn btn-primary">Los <span
                        className="icon-arrow-right"></span></Link>
                </div>
            </SpeakBubble>
        );

        const destinationBubbleUnSelected = (
            <SpeakBubble>
                <p className="text">
                    Die Bewohnerinnen und Bewohner der einzelnen Länder haben ihre ganz eigenen Traditionen und Sitten.
                    Wählen Sie einfach ein Land aus, dann werden Sie mehr darüber erfahren!
                </p>
            </SpeakBubble>
        );

        return (
            <motion.div className="page-wrapper align-center destinations"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: 1.5}}>
                <div className="d-none d-md-block">
                    <TravelLogo/>
                </div>
                <Container fluid>
                    <Row>
                        <Col xs={12}
                             className="destinations__intro mb-4 d-flex justify-content-center justify-content-md-start">
                            <SlideDown closed={this.state.selectStatus}>
                                <div className="pt-md-4 text--small text-center text-md-left mw-300">
                                    Wählen Sie das Land aus, das Sie entdecken möchten.
                                    <span className="d-none d-md-inline">  Auf der Weltkarte sehen Sie direkt, wohin die Reise geht.</span>
                                </div>
                            </SlideDown>
                        </Col>
                        <Col xs={12} md={4} xl={2}>
                            <div className={"destinations__selection " + this.state.selectStatus}>
                                <SlideDown closed={this.state.selectStatus}>
                                    {this.destinationsMapped}
                                </SlideDown>
                            </div>
                        </Col>
                        <Col xs={12} md={8} xl={10}>
                            <Row>
                                <Col xs={12} xl={8}
                                     className="destinations__map d-none d-md-block mb-md-4">
                                    <Tilt options={{max: 35}}>
                                        <div className="destinations__map-placeholder">
                                            <img src={Raster} alt="Raster"/>
                                        </div>
                                        <div className="destinations__map-countries">
                                            <img
                                                src={this.state.selectStatus ? this.state.activeDest.image : Countries}
                                                alt="Länderauswahl"/>
                                        </div>
                                    </Tilt>
                                    <div className={'destinations__map-slogan mt-4 ' + (this.state.selectStatus ? 'selected' : 'unselected')}>
                                        <h3>{this.state.activeDest.slogan}<span>{this.state.activeDest.name}</span></h3>
                                    </div>
                                </Col>
                                <Col xs={12} xl={4}
                                     className={"destinations__plan d-flex justify-content-end " + (this.state.selectStatus ? "selection-made" : "selection-todo")}>
                                    {this.state.selectStatus ? destinationBubbleSelected : destinationBubbleUnSelected}
                                    <Guido/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <div></div>
            </motion.div>
        )
    }
};

export default Destinations;
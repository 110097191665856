import axios from 'axios';
import {POSTS_SUCCESS, POSTS_ERROR} from "./types";

//Send post
export const sendPost = (postMessage) => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const res = await axios.post('/api/posts/', postMessage, config);

        dispatch({
            type: POSTS_SUCCESS
        });
    } catch (err) {
        dispatch({
            type: POSTS_ERROR
        })
    }
};
import React from "react";
import {motion} from "framer-motion";
import TravelLogo from "../layout/TravelLogo";
import {Container} from "react-bootstrap";
import SpeakBubble from "../layout/SpeakBubble";
import {Link, Redirect} from "react-router-dom";
import Guido from "../animations/Guido";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {sendPost} from "../../actions/posts";
import {SlideDown} from "react-slidedown";

class Message extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formValueName: '',
            formValueNameFilled: true,
            formValueTextarea: '',
            formValueTextareaFilled: true,
            sent: false
        };
    }

    onChangeFormName = (e) => {
        if (e.target.value.length > 0) {
            this.setState({
                formValueName: e.target.value,
                formValueNameFilled: true
            });
        } else {
            this.setState({
                formValueName: e.target.value,
                formValueNameFilled: false
            });
        }
    };

    onChangeFormTextArea = (e) => {
        if (e.target.value.length > 0) {
            this.setState({
                formValueTextarea: e.target.value,
                formValueTextareaFilled: true
            });
        } else {
            this.setState({
                formValueTextarea: e.target.value,
                formValueTextareaFilled: false
            });
        }
    };

    onSubmitFormExtend = async (e) => {
        e.preventDefault();

        let textAreaFilled = this.state.formValueTextareaFilled;
        let nameFilled = this.state.formValueNameFilled;

        if (this.state.formValueName.length > 0) {
            nameFilled = true;
            this.setState({
                formValueNameFilled: true
            });
        } else {
            nameFilled = false;
            this.setState({
                formValueNameFilled: false
            });
        }

        if (this.state.formValueTextarea.length > 0) {
            textAreaFilled = true;
            this.setState({
                formValueTextareaFilled: true
            });
        } else {
            textAreaFilled = false;
            this.setState({
                formValueTextareaFilled: false
            });
        }

        if (nameFilled && textAreaFilled) {
            const formValues = {
                name: this.state.formValueName,
                text: this.state.formValueTextarea
            };

            await this.props.sendPost(formValues);
            this.setState({
                sent: true
            });
        }
    };

    onSubmitForm = async (e) => {
        e.preventDefault();

        if (this.state.formValueTextarea.length > 0) {
            this.setState({
                formValueTextareaFilled: true
            });
        } else {
            this.setState({
                formValueTextareaFilled: false
            });
        }

        if (this.state.formValueTextareaFilled) {
            const formValues = {
                name: this.props.firstName + ' ' + this.props.lastName,
                text: this.state.formValueTextarea
            };
            await this.props.sendPost(formValues);
            this.setState({
                sent: true
            });
        }
    };

    formFields = '';

    render() {

        if (this.props.isAuthenticated && this.props.firstName !== null && this.props.firstName.length > 0 && this.props.lastName !== null && this.props.lastName.length > 0) {
            this.formFields = (
                <form onSubmit={this.onSubmitForm} className="message__form">
                    <div>
                    <textarea placeholder="Mitteilung" onChange={this.onChangeFormTextArea}
                              value={this.state.formValueTextarea} className="message__form-textarea"/>
                        <button type="submit" onSubmit={this.onSubmitForm}
                                className="message__form-submit icon-submit" value={''}/>
                    </div>
                    <SlideDown closed={this.state.formValueTextareaFilled}>
                        <p className="text--small mt-1">
                            Bitte trage eine Mitteilung ein.
                        </p>
                    </SlideDown>
                </form>
            );
        } else {
            this.formFields = (
                <form onSubmit={this.onSubmitFormExtend} className="message__form">
                    <input placeholder="Name" type="text" onChange={this.onChangeFormName}
                           value={this.state.formValueName} className="message__form-input"/>
                    <SlideDown closed={this.state.formValueNameFilled}>
                        <p className="text--small mt-2">
                            Bitte trage deinen Namen ein.
                        </p>
                    </SlideDown>
                    <div className="message__form-submit-wrapper">
                        <textarea placeholder="Mitteilung" onChange={this.onChangeFormTextArea}
                                  value={this.state.formValueTextarea} className="message__form-textarea mt-2"/>
                        <button type="submit" onSubmit={this.onSubmitForm}
                                className="message__form-submit icon-submit" value={''}/>
                    </div>
                    <SlideDown closed={this.state.formValueTextareaFilled}>
                        <p className="text--small mt-1">
                            Bitte trage eine Mitteilung ein.
                        </p>
                    </SlideDown>
                </form>
            );
        }

        if (this.state.sent) {
            return (
                <Redirect to="/thank-you"/>
            )
        } else {
            return (
                <motion.div className="page-wrapper align-center message"
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{duration: 1.5}}>
                    <TravelLogo/>
                    <Container className="d-flex justify-content-center flex-column align-items-center">
                        <SpeakBubble>
                            <p className="text--small mb-4">
                                Wie hat Ihnen die virtuelle Reise gefallen?<br/>
                                Schreiben Sie uns Ihre Meinung!
                            </p>
                            {this.formFields}
                            <Guido/>
                        </SpeakBubble>
                        <Link className="mt-4 message__continue-link" to="/thank-you">Ich sage ohne Rückmeldung leise
                            Servus.</Link>
                    </Container>
                    <div></div>
                </motion.div>
            )
        }
    }
}

Message.propTypes = {
    sendPost: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    firstName: state.user.firstName,
    lastName: state.user.lastName
});

export default connect(mapStateToProps, {sendPost})(Message);
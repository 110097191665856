import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import {motion} from "framer-motion";

import Helicopter from './../../animations/Helikopter_Animation';
import Airplane from './../../animations/Flugzeug_Animation';
import Boat from './../../animations/Schiff_Animation';
import Rocket from './../../animations/Rakete_Animation';
import Train from './../../animations/Zug_Animation';

const Loading = (props) => {

    const loadingText = [
        {
            country: 'andruvien',
            text: 'Ihre Reise geht los! Hoffentlich sind Sie in Feierstimmung.'
        },
        {
            country: 'bardivien',
            text: 'Ihre Reise geht los! Bald werden Sie durchatmen können.'
        },
        {
            country: 'duenebarg',
            text: 'Ihre Reise geht los! Übertreiben Sie es nicht mit dem heissen Rum.'
        },
        {
            country: 'fouchal',
            text: 'Ihre Reise geht los! Den Weihnachtsstress können Sie zu Hause lassen.'
        },
        {
            country: 'kurolia',
            text: 'Ihre Reise geht los! Es erwartet Sie ein tausendfaches Weihnachtsfest.'
        },
        {
            country: 'mondovien',
            text: 'Ihre Reise geht los! Es wird ausserordentlich bärtig.'
        },
        {
            country: 'nulap',
            text: 'Ihre Reise geht los! Bald werden Sie buchstäblich keine Zeit haben.'
        },
        {
            country: 'strizzia',
            text: 'Ihre Reise geht los! Freuen Sie sich auf eine besondere Charme-Offensive.'
        },
        {
            country: 'ubia',
            text: 'Ihre Reise geht los! Machen Sie sich darauf gefasst, dass alles anders wird.'
        },
        {
            country: 'zastavania',
            text: 'Ihre Reise geht los! Dieser Urlaub ist buchstäblich im Fluss.'
        }
    ];

    const [redirectTimer, setRedirectTimer] = useState(false);
    const redUrl = props.match.params.redurl;
    const animations = [
        <Airplane/>,
        <Helicopter/>,
        <Rocket/>,
        <Boat/>,
        <Train/>
    ];

    const randomize = (myArray) => {
        return myArray[Math.floor(Math.random() * myArray.length)];
    };

    setTimeout(() => {
        setRedirectTimer(true);
    }, 5000);

    return (
        <motion.div className="page-wrapper align-center align-items-center loading"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 1.5}}>
            <div></div>
            {redirectTimer ? <Redirect to={'/destinations/' + redUrl}/> : randomize(animations)}
            <p className="text">{loadingText.find(element => element.country === redUrl).text}</p>
        </motion.div>
    )
};

export default Loading;
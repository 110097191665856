import React, {useState} from 'react';
import AnimateCC from "react-adobe-animate";

const Rakete_Animation = () => {
    const [, setAnimationObject] = useState(null);
    const getAnimationObject = obj => setAnimationObject(obj);

    return (
        <div className="animation animation-rocket">
            <AnimateCC
                animationName="RaketeAnimation"
                getAnimationObject={getAnimationObject}
                composition="81115028747444FAA6227DEA6145F27C"
            />
        </div>
    );
};

export default Rakete_Animation;

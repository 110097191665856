import React from 'react';
import SpeakBubble from './../layout/SpeakBubble';
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import {motion} from "framer-motion";
import TravelLogo from "../layout/TravelLogo";
import Guido from './../animations/Guido';

const ThankYou = () => {
    return (
        <motion.div className="page-wrapper align-center thank-you"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 1.5}}>
            <TravelLogo/>
            <Container className="d-flex justify-content-center flex-column align-items-center">
                <SpeakBubble>
                    <h1 className="text-left">Vielen Dank!</h1>
                    <p className="text--small mb-4">
                        Im Namen von DACHCOM TRAVEL bedanke ich mich bei Ihnen für Ihr Vertrauen. Ich hoffe, die virtuelle Weihnachtsreise hat Ihnen gefallen, und freue mich darauf, Sie bald wieder begrüssen zu dürfen. Übrigens: Ihre Reise war klimaneutral und enorm kosteneffizient.<br/><br/>
                        Vielen Dank<br/>
                        Guido und das gesamte Team von DACHCOM TRAVEL
                    </p>
                    <Guido/>
                </SpeakBubble>
                <Link to="/donation" className="thank-you__continue-link mt-4 icon-arrow-right"></Link>
            </Container>
            <div></div>
        </motion.div>
    )
};

export default ThankYou;
import {AUTH_SUCCESS, AUTH_ERROR, LOGOUT} from "../actions/types";

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    loading: true,
    user: null
};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case AUTH_SUCCESS:
            localStorage.setItem('token', payload.token);
            return {
              ...state,
              isAuthenticated: true,
              loading: false,
              user: payload
            };
        case LOGOUT:
        case AUTH_ERROR:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false
            };
        default:
            return state;
    }
};
import React from 'react';
import {Link} from 'react-router-dom';
import {motion} from "framer-motion";
import {Container} from "react-bootstrap";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import TravelLogo from "../layout/TravelLogo";

const Welcome = (props) => {

    const greetingStart = 'Guten Tag';

    const greeting = () => {
        if (props.firstName !== null && props.firstName.length > 0 && props.lastName !== null && props.lastName.length > 0 && props.gender !== null && props.gender.length > 0) {
            return (
                <h1>{greetingStart}<br/>{(props.gender === 'm' ? ' Herr ' : ' Frau ') + props.firstName + ' ' + props.lastName}</h1>
            );
        } else {
            return <h1>{greetingStart}</h1>
        }
    };

    return (
        <motion.div className="page-wrapper align-center welcome"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 1.5}}>
            <TravelLogo/>
            <div className="content-wrapper">
                <Container fluid className="d-flex justify-content-center">
                    <div className="mw-300">
                        {greeting()}
                    </div>
                </Container>
                <Container fluid className="d-flex justify-content-center">
                    <p className="text text-center mw-500">
                        DACHCOM TRAVEL begrüsst Sie herzlich.<br/>
                        Wir freuen uns sehr, dass Sie gemeinsam mit uns eine aussergewöhnliche Reise machen.<br/><br/>
                        Gleichzeitig wünschen wir Ihnen frohe Weihnachten und eine besinnliche Adventszeit!
                    </p>
                </Container>
                <Container fluid className="d-flex justify-content-center">
                    <Link to="/welcome/ready" className="icon-arrow-right"></Link>
                </Container>
            </div>
            <div></div>
        </motion.div>
    )
};

Welcome.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    gender: PropTypes.string
};

const mapStateToProps = state => ({
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    gender: state.user.gender
});

export default connect(mapStateToProps)(Welcome);
import React from 'react';

const SpeakBubble = (props) => {
    return (
        <div className="speak-bubble">
            {props.children}
        </div>
    )
};

export default SpeakBubble;
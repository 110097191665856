import {USER_LOADED, USER_ERROR} from "../actions/types";

const initialState = {
    accessCode: '',
    firstName: '',
    lastName: '',
    gender: ''
};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case USER_LOADED:
            return {
                ...state,
                accessCode: payload.accessCode,
                firstName: payload.firstName,
                lastName: payload.lastName,
                gender:  payload.gender
            };
        case USER_ERROR:
            return {
                ...state
            };
        default:
            return state;
    }
};
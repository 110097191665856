import React from 'react';
import SpeakBubble from './../layout/SpeakBubble';
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import {motion} from "framer-motion";
import TravelLogo from "../layout/TravelLogo";
import Guido from './../animations/Guido';

const TravelAgent = () => {
    return (
        <motion.div className="page-wrapper align-center travel-agent"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 1.5}}>
            <TravelLogo/>
            <Container fluid className="d-flex justify-content-center">
                <SpeakBubble>
                    <h1 className="text-left">Hallo!</h1>
                    <p className="text--small mb-4">
                        Ich bin Guido, Ihr persönlicher Reiseführer. Als Forscher und Tourismusexperte habe ich die
                        einzelnen Länder und Regionen während Jahrzehnten erforscht und erkundet. Mittlerweile bin ich
                        aber im Ruhestand und gebe mein Wissen und meine Begeisterung in Reiseführungen
                        weiter.<br/><br/>
                        Ich freue mich sehr, Sie im Namen von DACHCOM TRAVEL begrüssen zu dürfen.
                    </p>
                    <Link to="/destinations" className="btn color-primary">Los geht’s!</Link>
                    <Guido/>
                </SpeakBubble>
            </Container>
            <div></div>
        </motion.div>
    )
};

export default TravelAgent;
import axios from 'axios';
import {AUTH_SUCCESS, AUTH_ERROR, LOGOUT} from "./types";
import {setAuthToken} from './../utils/setAuthToken';

//Load User
export const authUser = (accessCode) => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const res = await axios.get('/api/auth/' + accessCode);
        dispatch({
            type: AUTH_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: AUTH_ERROR
        })
    }
};

//Logout / Clear Profile
export const logout = () => dispatch => {
    dispatch({type: LOGOUT});
};